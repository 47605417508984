
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, toastController, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'InseratCreateStep4',
  data () {
    return {
      finishedLoading: false,
      errorWhileLoading: false,
      errorWhileCreating: false,
      persistedInserat: null
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonSpinner
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' })
    }

    // check if data is collected from previous steps
    if (!localStorage.inseratType){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratTitle){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratDescription){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCategoryId){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratPrice){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCurrency){
      this.$router.push({ path: '/inserat/create' })
    }
    if (localStorage.inseratIsGoodCause && !localStorage.inseratDonationRecipientId){
      this.$router.push({ path: '/inserat/create' })
    }

    // check step
    if (localStorage.inseratStep !== 4){
      // redirect to right step
      if (localStorage.inseratStep == 0){
        this.$router.push({ path: '/inserat/create' });
      } else {
        this.$router.push({ path: '/inserat/create/step-' + localStorage.inseratStep });
      }
    }

  },
  mounted() {

    const inseratData = {
      'title': localStorage.inseratTitle,
      'description': localStorage.inseratDescription,
      'category': '/categories/' + localStorage.inseratCategoryId,
      'price': parseInt(localStorage.inseratPrice),
      'currency': localStorage.inseratCurrency,
      'inseratType': localStorage.inseratType,
      'isDonation': (localStorage.inseratIsGoodCause == 'true')
    };

    if (localStorage.inseratIsGoodCause == 'true'){
        inseratData['donationRecipient'] = '/donation_recipients/' + localStorage.inseratDonationRecipientId;
    }

    // post inserat
    axios.post(process.env.VUE_APP_API_URL + '/inserats', inseratData, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
    .then(response => {
        localStorage.persistedInserat = response.data.uuid;
        this.nextStep();
    })
    .catch(error => {
      this.errorWhileCreating = true;
    }).finally(() => {
        this.finishedLoading = true;
    });

  },
  methods: {
    goToFirstStep(){
      // reset inserat local storage
      localStorage.inseratStep = 0;
      localStorage.inseratType = null;
      localStorage.inseratTitle = null;
      localStorage.inseratDescription = null;
      localStorage.inseratCategoryId = null;
      localStorage.inseratPrice = null;
      localStorage.inseratCurrency = null;
      localStorage.inseratIsGoodCause = null;
      localStorage.inseratDonationRecipient = null;
      localStorage.inseratDonationRecipientId = null;
      this.$router.push({ path: '/inserat/create' })
    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    nextStep() {
        // go to next step
        localStorage.inseratStep = 5;
        this.$router.push({ path: '/inserat/create/step-5' })
    }
  }
});

