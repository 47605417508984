<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Schritt 4</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="errorWhileCreating">
      <div id="container">
        <strong class="capitalize">Something unexpected happened</strong>
        <p>Please Try Again</p>
        <ion-button size="large" class="topMargins sideMargins" @click="goToFirstStep()" expand="block" color="danger">Nochmal versuchen</ion-button>
      </div>
    </ion-content>

    <ion-content v-if="!errorWhileCreating" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, toastController, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'InseratCreateStep4',
  data () {
    return {
      finishedLoading: false,
      errorWhileLoading: false,
      errorWhileCreating: false,
      persistedInserat: null
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonSpinner
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' })
    }

    // check if data is collected from previous steps
    if (!localStorage.inseratType){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratTitle){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratDescription){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCategoryId){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratPrice){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCurrency){
      this.$router.push({ path: '/inserat/create' })
    }
    if (localStorage.inseratIsGoodCause && !localStorage.inseratDonationRecipientId){
      this.$router.push({ path: '/inserat/create' })
    }

    // check step
    if (localStorage.inseratStep !== 4){
      // redirect to right step
      if (localStorage.inseratStep == 0){
        this.$router.push({ path: '/inserat/create' });
      } else {
        this.$router.push({ path: '/inserat/create/step-' + localStorage.inseratStep });
      }
    }

  },
  mounted() {

    const inseratData = {
      'title': localStorage.inseratTitle,
      'description': localStorage.inseratDescription,
      'category': '/categories/' + localStorage.inseratCategoryId,
      'price': parseInt(localStorage.inseratPrice),
      'currency': localStorage.inseratCurrency,
      'inseratType': localStorage.inseratType,
      'isDonation': (localStorage.inseratIsGoodCause == 'true')
    };

    if (localStorage.inseratIsGoodCause == 'true'){
        inseratData['donationRecipient'] = '/donation_recipients/' + localStorage.inseratDonationRecipientId;
    }

    // post inserat
    axios.post(process.env.VUE_APP_API_URL + '/inserats', inseratData, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
    .then(response => {
        localStorage.persistedInserat = response.data.uuid;
        this.nextStep();
    })
    .catch(error => {
      this.errorWhileCreating = true;
    }).finally(() => {
        this.finishedLoading = true;
    });

  },
  methods: {
    goToFirstStep(){
      // reset inserat local storage
      localStorage.inseratStep = 0;
      localStorage.inseratType = null;
      localStorage.inseratTitle = null;
      localStorage.inseratDescription = null;
      localStorage.inseratCategoryId = null;
      localStorage.inseratPrice = null;
      localStorage.inseratCurrency = null;
      localStorage.inseratIsGoodCause = null;
      localStorage.inseratDonationRecipient = null;
      localStorage.inseratDonationRecipientId = null;
      this.$router.push({ path: '/inserat/create' })
    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    nextStep() {
        // go to next step
        localStorage.inseratStep = 5;
        this.$router.push({ path: '/inserat/create/step-5' })
    }
  }
});

</script>

<style scoped>

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.imageCircleDenied {
  border: 2px solid #c61d53;
}

.imageCircleAccepted {
  border: 2px solid #1dc620;
}

.rounded-image {
  border-radius: 50%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-btn-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  width: 100%;
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>